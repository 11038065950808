import React, { useState } from 'react';
import Popup from "reactjs-popup";
import { t } from '../services/i18n';
import "../styles/Popup.css";
import Cookies from "universal-cookie";


const KEYS = 'hidding-survey';

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}





export default () => {
  const cookies = new Cookies();
  
  const hideUntilTomorrow = () => {
    let date = new Date();
    date.setDate(date.getDate() + 1);

    cookies.set(KEYS, "true", {
      path: "/",
      expires: date,
    });
  }

  const hideForever = () => {
    let date = new Date();
    date.setDate(date.getDate() + 10e5);

    cookies.set(KEYS, "true", {
      path: "/",
      expires: date,
    });
  }

  //const shouldShowPopup = () => !cookies.get(KEYS);

  // if (!shouldShowPopup()) {
  //   return null;
  // }

  return (
  <Popup open modal nested>
    {close => (
    <div className="modal-popup">
        <button className="close" onClick={() => {
          close();
          // hideUntilTomorrow();
        }}>
          &times;
        </button>
        <div className="header"> MAT Survey 2024 </div>
        <div className="content">
          {' '}
            {t('survey-message')}
            <br /><br />
            {t('survey-message_1')} <a className='mat-survey-lnk' href="https://itcsurvey.intracen.org/fs.aspx?surveyid=af517483b6b491c90279dedcd6310d6" target="_blank">MAT Survey 2024</a>
            <br /><br />
            {t('survey-message_2')}
            <br /><br />
            {t('survey-message_3')}
            <br /><br />
            {t('survey-message_4')}
            <br /><br />
        </div>

        {/* <button
        className="button"
        onClick={() => {
          close();
          hideForever();
        }}
      >
        Close and don't ask any more
      </button> */}
    </div>
    )}
    </Popup>  
    )
}

