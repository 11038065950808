import {connect} from 'react-redux'
import React, {Component} from 'react'

import {t} from '../services/i18n'
import VideoCoursesHome from '../components/VideoCoursesHome'
import Social from '../components/Social';

import Select from 'react-select';
import {fetchCountries, fetchEpmChart, fetchMacMapChart, fetchTradeMapChart, userActions} from '../actions'
import YouTube from 'react-youtube';

import RBCarousel from "react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";

import Popup from "reactjs-popup";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addTreemapModule from 'highcharts/modules/treemap';
import addExportModule from 'highcharts/modules/exporting';
import addHighchartsMore from 'highcharts/highcharts-more';


import {MoneyFormat} from '../services/util'

import publicIp from "public-ip";
import iplocation from "iplocation";

import Newsletters from '../components/Newsletters';

import UserSurveys from '../components/UserSurvey';

import i18next from 'i18next';

import SurveyModal from '../components/SurveyModal';

// init the module

addTreemapModule(Highcharts);
addHighchartsMore(Highcharts);
addExportModule(Highcharts);

class HomePage extends Component {

  state = {
    selectedCountry: null,
    rightIcon: null,
    leftIcon: null

  }
  optsYoutube = {
    height: '',
    width: '100%',
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 0
    }
  };

  componentWillMount() {

    this.props.getAllUsers();
    this.props.fetchCountries();
  }

  componentDidMount() {
    //this.props.dispatch(userActions.getAll());


    /*this.props.fetchMacMapChart('008');
    this.props.fetchTradeMapChart('008');
    this.props.fetchEpmChart('008');*/
    let userCountry = null;

    (async () => {

      iplocation(await publicIp.v4())
        .then((res) => {
          console.log(res);
          userCountry = res.countryCode;
          console.log(userCountry);
          let tempctrs = this.props.countries.filter(c => c.code === userCountry);
          //console.log(tempctrs);
          if (tempctrs.length === 1)
            this.handleChangeCountry(tempctrs[0]);
          else
            this.handleChangeCountry('757');
        })
        .catch(() => {
          console.log("error in getting Iplocation");
          let defCtryCode = this.props.countries.filter(c => c.code === 'CH');
          console.log(defCtryCode);
          this.handleChangeCountry(defCtryCode[0]);
        });
    })();

  }

  handleChangeCountry = (selectedCountry) => {
    if (selectedCountry) {
      this.setState({selectedCountry: selectedCountry});
      this._countrySelect.blur();
      this.props.fetchMacMapChart(selectedCountry.value);
      this.props.fetchTradeMapChart(selectedCountry.value);
      this.props.fetchEpmChart(selectedCountry.value);
    }
  }

  /*Carousel*/
  _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  onSelect = () => {};
  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({autoplay: !this.state.autoplay});
  };
  _changeIcon = () => {
    let {leftIcon, rightIcon} = this.state;
    leftIcon = leftIcon ? undefined : <span className="fa fa-glass"/>;
    rightIcon = rightIcon ? undefined : <span className="fa fa-music"/>;
    this.setState({leftIcon, rightIcon});
  };
  /*end carousel*/


  getChart = chart => {
//	this.chart = chart;
    chart.plotHeight = 732;
    chart.plotSizeY = 732;
    chart.chartHeight = 800;
    chart.plotBox.height = 732;
    //chart.reflow();
  }


  getChartMini = chart => {
    //	this.chart = chart;
    chart.plotHeight = 332;
    chart.plotSizeY = 332;
    chart.chartHeight = 400;
    chart.plotBox.height = 332;

    chart.plotWidth = 300;
    chart.plotSizeX = 300;
    chart.chartWidth = 340;
    chart.plotBox.width = 300;
    //chart.reflow();
  }

  render() {

    const MacMapChartOptions = {
      chart: {
        type: 'column',
        height: 400,

      },
      lang: {
        downloadJPEG: t('downloadJPEG'),
        downloadPDF: t('downloadPDF'),
        downloadPNG: t('downloadPNG'),
        downloadSVG: t('downloadSVG'),
        printChart: t('printChart')

      },
      legend: {
        layout: 'horizontal',
        backgroundColor: '#FFFFFF',
        /*floating: true,
        align: 'left',
        verticalAlign: 'top',
        x: 90,
        y: 45,*/
        labelFormatter: function () {
          return (this.name === "maxValue" ? t('mfn') : t('applied_tariff'));
        }
      },

      title: {
        text: (this.state.selectedCountry ? t('tariff_averages') + this.state.selectedCountry.label : " ")
      },
      subtitle: {
        text: t('source') + ': www.macmap.org'
      },

      yAxis: {
        min: 0,
        title: {
          text: t('tariff') + ' (%)'
        }
      },
      tooltip: {
        /*headerFormat: '<span style="font-size:10px">{point.key}</span><table>',*/
        formatter: function () {


          let pt_series_name;

          if (this.point.series.name === "maxValue")
            pt_series_name = 'MFN';
          else
            pt_series_name = t('applied_tariff');

          return '<span style="font-size:12px">' + this.point.category + '</span><table><tr><td style="color:'
            + this.point.series.color + ';padding:0">' + pt_series_name + ': </td><td style="padding:0"><b>'
            + this.point.y + '</b></td></tr></table>';


        },
        /*pointFormatter: function(point) {
          var seriesNameConverter = {
              'maxValue': '<tr><td style="color:' + this.series.color + ';padding:0">MFN: </td><td style="padding:0"><b>{{this.point.y}}</b></td></tr>',
              'minValue': '<tr><td style="color:' + this.series.color + ';padding:0">Effectively applied tariffs: </td><td style="padding:0"><b>{{this.point.y}}</b></td></tr>'
          };

          return seriesNameConverter[this.series.name];

      },*/
        /*	pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.2f} </b></td></tr>',
          footerFormat: '</table>',*/
        shared: false,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      xAxis: {
        categories: [t('allproducts'), t('Industrial'), t('Agricultural')],
        crosshair: true,
        title: {
          text: t('Products')
        }
      },

      series: this.props.macMapChartData,

      exporting: {
        enabled: true
      }

    }

    const MacMapChartOptionsMini = {
      chart: {
        type: 'column',
        height: 400,
        width: 300

      },

      legend: {
        layout: 'horizontal',
        backgroundColor: '#FFFFFF',

        labelFormatter: function () {
          return (this.name == "maxValue" ? t('mfn') : t('applied_tariff'));
        }
      },

      title: {
        text: (this.state.selectedCountry ? t('tariff_averages') + this.state.selectedCountry.label : " ")
      },


      yAxis: {
        min: 0,
        title: {
          text: t('tariff') + ' (%)'
        }
      },
      tooltip: {
        /*headerFormat: '<span style="font-size:10px">{point.key}</span><table>',*/
        formatter: function () {


          let pt_series_name = '';

          if (this.point.series.name == "maxValue")
            pt_series_name = t('mfn');
          else
            pt_series_name = t('applied_tariff');

          return '<span style="font-size:12px">' + this.point.category + '</span><table><tr><td style="color:'
            + this.point.series.color + ';padding:0">' + pt_series_name + ': </td><td style="padding:0"><b>'
            + this.point.y + '</b></td></tr></table>';


        },

        shared: false,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      xAxis: {
        categories: [t('allproducts'), t('Industrial'), t('Agricultural')],
        crosshair: true,
        title: {
          text: t('Products')
        }
      },

      series: this.props.macMapChartData,

      exporting: {
        enabled: false
      }

    }


    const TradeMapChartOptions = {
      ...{
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          //colorCount:12,
          plotShadow: false
        },
        lang: {
          downloadJPEG: t('downloadJPEG'),
          downloadPDF: t('downloadPDF'),
          downloadPNG: t('downloadPNG'),
          downloadSVG: t('downloadSVG'),
          printChart: t('printChart')

        },
        title: {
          text: "TradeMap"
        },
        subtitle: {
          text: t('source') + ': https://www.trademap.org'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'


        },

        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
              '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FFEC33'],

            startAngle: -90,
            endAngle: 270,
            center: ['50%', '50%'],
            size: '100%'
          }
        }
      }, ...this.props.tradeMapChartData
    };

    const TradeMapChartOptionsMini = {
      ...{
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          //colorCount:12,
          height: 400,
          width: 300
        },

        /*title: {
            text: '<b>$5.3 Billion</b><b>5%</b> of world export',
            align: 'center',
            verticalAlign: 'middle',
            y: -25
        },*/
        exporting: {
          enabled: false
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },

        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
              '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#FFEC33'],

            startAngle: -90,
            endAngle: 270,
            center: ['55%', '20%'],
            size: '100%'
          }
        }


      }, ...this.props.tradeMapChartData
    };

    const EpmChartOptions = {
      series:  this.props.epmChartData,
      height: 800,
      title: {
        text: (this.state.selectedCountry ? t('epmtitle') + this.state.selectedCountry.label : "")

      },
      subtitle: {
        text: t('source') + ': exportpotential.intracen.org'
      },
      lang: {
        downloadJPEG: t('downloadJPEG'),
        downloadPDF: t('downloadPDF'),
        downloadPNG: t('downloadPNG'),
        downloadSVG: t('downloadSVG'),
        printChart: t('printChart')

      },
      tooltip: {
        formatter: function () {

          let src = '<div id="tooltip-content">    <div layout="row" class="layout-row">' +
            '<span class="tooltip-title">' + (this.point.itemCode ? this.point.itemCode : "") + ' ' + this.point.name + '</span>' +

            '</div>    <div class="tooltip-body"></div></div></div>' +
            ' <div  class="tooltip-values ng-scope" style="">' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<span ><strong class="tooltip-money ng-binding">Export potential </strong></span>' +
            '<span flex="none" style="padding-left: 12px;" class="flex-none">$' + MoneyFormat(this.point.value, 2) + ' </span>' +
            '</div>' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<strong class="tooltip-money">Actual exports </strong>' +
            '<span flex="none" style="padding-left: 12px;" class="ng-binding flex-none">$' + MoneyFormat(this.point.exportValue, 2) + ' </span>' +
            '</div>' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<span >' +
            '<strong class="tooltip-money">Untapped potential remaining in individual countries</strong>  ' +
            '<a href="/#/resources-glossary#realized-potential"><span class="link-untapped-potential legend-question" role="button" tabindex="0"></span></a>' +
            '</span>' +
            '<span flex="none" style="padding-left: 12px;" class="flex-none">$' + MoneyFormat(Math.round(this.point.value - this.point.value * this.point.realizedPotential), 2) + '</span>' +
            '</div>	<br></div>';

          /*	return'<span style="font-size:12px">' + this.point.category + '</span><table><tr><td style="color:'
            + this.point.series.color+';padding:0">'+pt_series_name+': </td><td style="padding:0"><b>'
            + this.point.y + '</b></td></tr></table>';*/
          return src;

        },

        shared: false,
        useHTML: true

      }
    };

    const EpmChartOptionsMini = {
      series: this.props.epmChartData,
      height: 300,
      width: 300,
      title: {
        text: (this.state.selectedCountry ? t('epmtitle') + this.state.selectedCountry.label : "")

      },

      exporting: {
        enabled: false
      },
      tooltip: {
        formatter: function () {

          let src = '<div id="tooltip-content">    <div layout="row" class="layout-row">' +
            '<span class="tooltip-title">' + (this.point.itemCode ? this.point.itemCode : "") + ' ' + this.point.name + '</span>' +

            '</div>    <div class="tooltip-body"></div></div></div>' +
            ' <div  class="tooltip-values ng-scope" style="">' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<span ><strong class="tooltip-money ng-binding">Export potential </strong></span>' +
            '<span flex="none" style="padding-left: 12px;" class="flex-none">$' + MoneyFormat(this.point.value, 2) + ' </span>' +
            '</div>' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<strong class="tooltip-money">Actual exports </strong>' +
            '<span flex="none" style="padding-left: 12px;" class="ng-binding flex-none">$' + MoneyFormat(this.point.exportValue, 2) + ' </span>' +
            '</div>' +
            '<div class="tooltip-line layout-row" layout="row">' +
            '<span >' +
            '<strong class="tooltip-money">Untapped potential remaining in individual countries</strong>  ' +
            '<a href="/#/resources-glossary#realized-potential"><span class="link-untapped-potential legend-question" role="button" tabindex="0"></span></a>' +
            '</span>' +
            '<span flex="none" style="padding-left: 12px;" class="flex-none">$' + MoneyFormat(Math.round(this.point.value - this.point.value * this.point.realizedPotential), 2) + '</span>' +
            '</div>	<br></div>';

          /*	return'<span style="font-size:12px">' + this.point.category + '</span><table><tr><td style="color:'
            + this.point.series.color+';padding:0">'+pt_series_name+': </td><td style="padding:0"><b>'
            + this.point.y + '</b></td></tr></table>';*/
          return src;

        },

        shared: false,
        useHTML: true

      }
    };


    return (

      <div>

        <section id="itc-home-banner">
          <div className="container">
            <div className="row introCont">
              <div className="banner-left col-12 col-sm-12 col-md-6">
                <h2>{t('app_title')}</h2>
                <p>{t('app_desc')}<br/><br/>
                  {t('app_desc_p1')}<br/><br/>
                  {t('app_desc_p2')}</p>
                <a href="https://mas-admintools.intracen.org/accounts/Registration.aspx" target="_blank">
                  <button className="intro-btn">{t('signup')}</button>
                </a>
              </div>
              <div className="banner-right col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                <YouTube className="embed-responsive-item" videoId={t('home-youtube')} opts={this.optsYoutube}/>
              </div>
            </div>
          </div>
        </section>

        <section id="mat-tool-section" className="content">

          <div className="container">
            <div className="row text-center">
              <div className="featured col-12 col-sm-12">
                <div className="container-fluid">
                  <div className="row text-center">
                    {/* <div className="col-12 col-sm-12 col-md-4">
								</div> */}
                    <div className="col-12 col-sm-12 col-md-12 text-center">
                      <h2 className="section-title mt-4">{t('section_mat')}</h2>
                      <hr className="marketHr"></hr>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-sm-12 col-md-4 mt-2">

                      {/*<CountryCombo triggerChartRefresh={	this.props.fetchChart('008')}></CountryCombo>*/}
                      <Select ref={c => (this._countrySelect = c)} className="countrySelect"
                        /*noOptionsMessage="Select a country"*/
                              isSearchable="true"
                              value={this.state.selectedCountry}
                              onChange={this.handleChangeCountry}
                              placeholder={t('lbl_select')}
                              options={this.props.countries}


                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div className="featured col-12 col-sm-12">
                <div className="container-fluid">
                  <div className="row text-center">
                    <div className="col-12 col-sm-12 col-md-4">
                      <div className="featured-mat pointer">
                        <div className="header-featured-mat"></div>

                        <div className="featured-tool-data">

                          <Popup trigger={<span><h4>Trade Map</h4><div className="mat-text ">{t('chart_tm_title')}</div></span>}
                                 modal
                                 contentStyle={{height: '450px'}}>
                            <div>
                              <HighchartsReact
                                callback={this.getChart}
                                highcharts={Highcharts}
                                options={TradeMapChartOptions}
                              >
                              </HighchartsReact></div>
                          </Popup>
                          <HighchartsReact
                            callback={this.getChart}
                            highcharts={Highcharts}
                            options={TradeMapChartOptionsMini}
                          >
                          </HighchartsReact>
                        </div>
                        {/* <LocalizedLink to="https://www.trademap.org/" className="button btnTools">{ t('chart_btn_tm')}</LocalizedLink>  */}
                        <a href={"https://www.trademap.org/" + i18next.language + "/index.aspx"} target="_blank">
                          <button className="button btnTools">{t('chart_btn_tm')}</button>
                        </a>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-4">
                      <div className="featured-mat pointer">
                        <div className="header-featured-mat"></div>

                        <div className="featured-tool-data">


                          <Popup trigger={<span><h4>Market Access Map</h4>
								<div className="mat-text">
									{t('chart_mm_title')}
									</div></span>} modal
                                 contentStyle={{height: '450px'}}
                          >
                            <div><HighchartsReact
                              highcharts={Highcharts}
                              options={MacMapChartOptions}
                            /></div>
                          </Popup>
                          <HighchartsReact

                            highcharts={Highcharts}
                            options={MacMapChartOptionsMini}
                          />
                        </div>
                        <a href={"https://www.macmap.org/" + i18next.language + "/"} target="_blank">
                          <button className="button btnTools">{t('chart_btn_mm')}</button>
                        </a>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-4">
                      <div className="featured-mat pointer">
                        <div className="header-featured-mat"></div>

                        <div className="featured-tool-data">


                          <Popup trigger={<span>	<h4>Export Potential Map</h4>
									<div className="mat-text">
									{t('chart_epm_title')}
									</div></span>} modal
                                 contentStyle={{height: '450px'}}
                          >
                            <HighchartsReact callback={this.getChart}
                                             containerProps={{className: 'highcharts-container'}}
                                             highcharts={Highcharts}
                                             options={EpmChartOptions}
                            />
                          </Popup>
                          <HighchartsReact
                            callback={this.getChartMini}
                            highcharts={Highcharts}
                            options={EpmChartOptionsMini}
                          />

                        </div>
                        <a href={"http://exportpotential.intracen.org/" + i18next.language + "/#/home"} target="_blank">
                          <button className="button btnTools">{t('chart_btn_epm')}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider"></div>

              <div className="additional-mat col-12 col-sm-12 mb-5">
                <h4 className="section-title mt-5">{t('section_addl_mat')}</h4>
                <hr></hr>
                <div className="container-fluid">
                  <div className="row text-center">
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="https://mpi.intracen.org/home" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">Market Price Information</div>
                        </button>
                      </a>
                    </div>
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="https://www.investmentmap.org/home" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">Investment Map</div>
                        </button>
                      </a>
                    </div>
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="https://findrulesoforigin.org/" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">Rules of origin</div>
                        </button>
                      </a>
                    </div>
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="http://globaltradehelpdesk.org/en" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">Global Trade Helpdesk</div>
                        </button>
                      </a>
                    </div>
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="https://procurementmap.intracen.org/" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">Procurement Map</div>
                        </button>
                      </a>
                    </div>
                    <div className="mat-tools col-12 col-sm-6 col-md-4">
                      <a href="https://euromed.tradehelpdesk.org/en" target="_blank">
                        <button className="btnTools">
                          <div className="tool-title">EuroMed Trade Helpdesk</div>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <VideoCoursesHome></VideoCoursesHome>
        <Social></Social>
        <section id="testimonial-section" className="content">

          <div className="container">
            <h2 className="col-12 section-title text-center mt-4">{t('section_testimonials')}</h2>
            <hr></hr>
            <div className="row">

              <div id="testimonial-carousel" className="carousel slide col-12 col-sm-12">
                <div className="carousel-inner">
                  <RBCarousel
                    animation={true}
                    autoplay={this.state.autoplay}
                    slideshowSpeed={5000}
                    leftIcon={this.state.leftIcon}
                    rightIcon={this.state.rightIcon}
                    onSelect={this.onSelect}
                    ref={r => (this.slider = r)}
                    version={4}
                    indicators={true}
                  >
                    <div className="row align-items-center testimonialCont">
                      <div className="col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                        <div className="videoTestimonial embed-responsive-item">
                          <YouTube videoId="T4X-Mw78scA" opts={this.optsYoutube}/>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="testimonial-inner">
                          <div className="testimonial">“{t('lbl_testimonial_2')}”</div>
                          <div className="signature">- Shakhrior Sharafutdinov, JSC Kvartz Uzbekistan</div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center testimonialCont">
                      <div className="col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                        <div className="videoTestimonial embed-responsive-item">
                          <YouTube videoId="4Ihoh_i_p3U" opts={this.optsYoutube}/>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="testimonial-inner">
                          <div className="testimonial">“{t('lbl_testimonial_1')}”</div>
                          <div className="signature">- Urmat Takirov, UNDP Aid for Trade, Kyrgyzstan</div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center testimonialCont">
                      <div className="col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                        <div className="videoTestimonial embed-responsive-item">
                          <YouTube videoId="5lWEHtgUYmQ" opts={this.optsYoutube}/>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="testimonial-inner">
                          <div className="testimonial">“{t('lbl_testimonial')}”</div>
                          <div className="signature"> - Mahmoud El Awady</div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center testimonialCont">
                      <div className="col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                        <div className="videoTestimonial embed-responsive-item">
                          <img src="../img/MAT portal Quote card.png"></img>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 embed-responsive embed-responsive-16by9">
                        <div className="videoTestimonial embed-responsive-item"
                             alt="Federation of Women Entrepeneurs Association">
                          <img src="../img/MAT portal quote card 2.png" alt="DIRCO"></img>
                        </div>
                      </div>
                    </div>
                  </RBCarousel>
                </div>
                {/* <div className="col-12 col-sm-12">
							<ol className="carousel-indicators">
								<li data-target="#testimonial-carousel" data-slide-to="0" className="active"></li>
								<li data-target="#testimonial-carousel" data-slide-to="1" className=""></li>
							</ol>
						</div> */}
              </div>
            </div>
          </div>
        </section>
        <Newsletters></Newsletters>
        <UserSurveys></UserSurveys>
        <SurveyModal></SurveyModal>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  const {users, authentication} = state;
  const {user} = authentication;
  return {
    user,
    users,
    countries: state.countries.countries,
    macMapChartData: state.chartData.macMapChartData,
    tradeMapChartData: state.chartData.tradeMapChartData,
    epmChartData: state.chartData.epmChartData
  };
}


const mapDispatchToProps = dispatch => ({
  fetchMacMapChart: (countryId) => dispatch(fetchMacMapChart(countryId)),
  fetchTradeMapChart: (countryId) => dispatch(fetchTradeMapChart(countryId)),
  fetchEpmChart: (countryId) => dispatch(fetchEpmChart(countryId)),
  fetchCountries: () => dispatch(fetchCountries()),
  ...userActions
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
