import React from 'react'
import {t} from '../services/i18n'

import nlq1 from "../news-letter/2021-Q1-Market_Analysis_Services_Update.htm"
import nlq2 from "../news-letter/2021-Q2-Market_Analysis_Services_Update.htm"
import nlq3 from "../news-letter/2021-Q3-Market_Analysis_Services_Update.htm"
import nlq4 from "../news-letter/2021-Q4-Market_Analysis_Services_Update.htm"
import nlq5 from "../news-letter/2022-Q1-Market_Analysis_Services_Update.htm"
import nlq6 from "../news-letter/2022-Q2-Market_Analysis_Services_Update.htm"
import nlq7 from "../news-letter/2022-Q3-Market_Analysis_Services_Update.htm"
import nlq8 from "../news-letter/2022-Q4-Market_Analysis_Services_Update.htm"
import nlq9 from "../news-letter/2023-Q1-Market_Analysis_Services_Update.htm"
import nlq10 from "../news-letter/2023-Q2-Market_Analysis_Services_Update.htm"
import nlq11 from "../news-letter/2023-Q3-Market_Analysis_Services_Update.htm"
import nlq12 from "../news-letter/2023-Q4-Market_Analysis_Services_Update.htm"
import nlq13 from "../news-letter/2024-Q1-Market_Analysis_Services_Update.htm"
import nlq14 from "../news-letter/2024-Q2-Market_Analysis_Services_Update.htm"
import nlq15 from "../news-letter/2024-Q3-Market_Analysis_Services_Update.htm"
import nlq16 from "../news-letter/2024-Q4-Market_Analysis_Services_Update.htm"

export default () => (
  <section id="links-section">
    <div className="container">
      <h2 className="col-12 section-title text-center mt-5"> {t('section_newsletter_archive')} </h2>
      <hr></hr>
      <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq1}>
            <button className="btnNewsletters">{t('lbl_1_quarter')} 2021</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq2}>
            <button className="btnNewsletters">{t('lbl_2_quarter')} 2021</button>
          </a>
        </div>  
        <div className="col newsletter-download text-center">
           <a target="_blank" href={nlq3}>
            <button className="btnNewsletters">{t('lbl_3_quarter')} 2021</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq4}>
            <button className="btnNewsletters">{t('lbl_4_quarter')} 2021</button>
          </a>
        </div>
      </div>
      <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq5}>
            <button className="btnNewsletters">{t('lbl_1_quarter')} 2022</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq6}>
            <button className="btnNewsletters">{t('lbl_2_quarter')} 2022</button>
          </a>
        </div>  
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq7}>
            <button className="btnNewsletters">{t('lbl_3_quarter')} 2022</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq8}>
          <button className="btnNewsletters">{t('lbl_4_quarter')} 2022</button>
          </a>
        </div> 
      </div>
      <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq9}>
            <button className="btnNewsletters">{t('lbl_1_quarter')} 2023</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq10}>
            <button className="btnNewsletters">{t('lbl_2_quarter')} 2023</button>
          </a>
        </div>  
        <div className="col newsletter-download text-center">
           <a target="_blank" href={nlq11}>
            <button className="btnNewsletters">{t('lbl_3_quarter')} 2023</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq12}>
            <button className="btnNewsletters">{t('lbl_4_quarter')} 2023</button>
          </a>
        </div>
      </div>
      <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq13}>
            <button className="btnNewsletters">{t('lbl_1_quarter')} 2024</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq14}>
            <button className="btnNewsletters">{t('lbl_2_quarter')} 2024</button>
          </a>
        </div>  
        <div className="col newsletter-download text-center">
        <a target="_blank" href={nlq15}>
            <button className="btnNewsletters">{t('lbl_3_quarter')} 2024</button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq16}>
            <button className="btnNewsletters">{t('lbl_4_quarter')} 2024</button>
          </a>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <h2 className="section-title mt-4 subscribe-title"></h2>
          {/*<h2 className="section-title mt-4 subscribe-title">Subscribe to our newsletter</h2>
                <form className="">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <input className="email-input" type="text" placeholder="EMAIL"></input>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              
                            <button className="newsletterBtn">SUSBSCRIBE</button>
            </div>

            </form>*/}
        </div>
      </div>
    </div>
  </section>
)
