import React from "react";
import { t } from "../services/i18n";
import nlq1 from "../news-letter/2024-Q1-Market_Analysis_Services_Update.htm";
import nlq2 from "../news-letter/2024-Q2-Market_Analysis_Services_Update.htm";
import nlq3 from "../news-letter/2024-Q3-Market_Analysis_Services_Update.htm";
import nlq4 from "../news-letter/2024-Q4-Market_Analysis_Services_Update.htm";
import LocalizedLink from "../containers/LocalizedLink";

export default () => (
  <section id="links-section">
    <div className="container">
      <h2 className="col-12 section-title text-center mt-5">
        {t("section_newsletter")}
      </h2>
      <hr></hr>
      <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq4}>
            <button className="btnNewsletters">
              {t("lbl_4_quarter")} 2024
            </button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq3}>
            <button className="btnNewsletters">
              {t("lbl_3_quarter")} 2024
            </button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq2}>
            <button className="btnNewsletters">
              {t("lbl_2_quarter")} 2024
            </button>
          </a>
        </div>
        <div className="col newsletter-download text-center">
          <a target="_blank" href={nlq1}>
            <button className="btnNewsletters">
              {t("lbl_1_quarter")} 2024
            </button>
          </a>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <LocalizedLink to="news">
            <button className="button learning-button">
              {t("section_newsletter_archive")}
            </button>
          </LocalizedLink>
        </div>
      </div>
    </div>
  </section>
);
